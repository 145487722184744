<template>
  <main class="main profile-page page-inside">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="wrapper">
          <div class="profile-form">
            <div class="profile-form-wrapper">
              <div class="wrapper">
                <div class="title big">My Account</div>
                <div class="profile-left">
                  <ul class="nav">
                    <template v-for="(item, i) in profileNav">
                      <li :class="['item', {'active': item.page === activeProfilePage}]" @click="goToProfilePage(item.page)" :key="i">
                        <div class="desc">{{item.title}}</div>
                      </li>
                    </template>
                    <li class='item' @click="$parent.logout">
                      <div class="desc">Log out</div>
                    </li>
                  </ul>
                </div>
                <div class="profile-right">
                  <div class="content" v-if="activeProfilePage === 'my-products'">
                    <div class="content-wrapper">
                      <div class="table-above">
                        <div class="title big">Created items</div>
                        <button @click="$parent.goToPromtCreate" class="button border">Sell your items</button>
                      </div>
                      <div class="table-container table-container-depositHistory table-container-createdHistory">
                        <table>
                          <thead>
                            <tr v-if="$parent.createdHistory && $parent.createdHistory.length">
                              <td>
                                <div class="td-wrapper">#</div>
                              </td>
                              <td>
                                <div class="td-wrapper">PREVIEW/NAME</div>
                              </td>
                              <td>
                                <div class="td-wrapper">Category</div>
                              </td>
                              <td>
                                <div class="td-wrapper">Keywords</div>
                              </td>
                              <td>
                                <div class="td-wrapper">Status</div>
                              </td>
                              <td>
                                <div class="td-wrapper"></div>
                              </td>
                            </tr>
                            <tr v-else>
                              <td></td>
                            </tr>
                          </thead>
                          <tbody v-if="$parent.createdHistory && !$parent.createdHistory.length">
                            <tr>
                              <td class="bold">You can create an <span @click="$parent.goToPromtCreate">Item</span></td>
                            </tr>
                          </tbody>
                          <tbody v-if="$parent.createdHistory && $parent.createdHistory.length">
                            <tr v-for="(item,i) in $parent.createdHistory" :key="i">
                              <td class="nowrap"><div class="td-wrapper">{{item.id}}</div></td>
                              <td>
                                <div class="td-wrapper">
                                  <div class="item">
                                    <div class="img-container">
                                      <img v-if="item.doc_url != null" class="img" :src="$parent.imgDomain + item.doc_url" />
                                      <div v-else class="doc-img">
                                        <img src='./../assets/doc.svg' class="img"/>
                                      </div>
                                    </div>
                                    <div class="text">{{item.title}}</div>
                                  </div>
                                </div>
                              </td>
                              <td><div class="td-wrapper">{{categoryOptions.find(obj => obj.id === item.category_id).title}}</div></td>
                              <td><div class="td-wrapper">{{ item.keywords }}</div></td>
                              <td class="nowrap"><div class="td-wrapper">{{ item.status }}</div></td>
                              <td>
                                <div class="td-wrapper">
                                  <div class="delete" @click="deleteProduct(item.id)">
                                    <img src='./../assets/delete.svg' class="img"/>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="content" v-if="activeProfilePage === 'sale-history'">
                    <div class="content-wrapper">
                      <div class="table-above">
                        <div class="title big">Selling items</div>
                        <button @click="$parent.goToPromtCreate" class="button border">Sell your item</button>
                      </div>
                      <div class="table-container table-container-depositHistory">
                        <table>
                          <thead>
                            <tr v-if="$parent.depositHistory && sellHistory.length">
                              <td>
                                <div class="td-wrapper">#</div>
                              </td>
                              <td>
                                <div class="td-wrapper">CREATED</div>
                              </td>
                              <td>
                                <div class="td-wrapper">PREVIEW/NAME</div>
                              </td>
                              <td>
                                <div class="td-wrapper">Price</div>
                              </td>
                            </tr>
                            <tr v-else>
                              <td></td>
                            </tr>
                          </thead>
                          <tbody v-if="$parent.depositHistory && !sellHistory.length">
                            <tr>
                              <td class="bold">You have no sold items</td>
                            </tr>
                          </tbody>
                          <tbody v-if="$parent.depositHistory && sellHistory.length">
                            <tr v-for="(item,i) in sellHistory" :key="i">
                              <td><div class="td-wrapper">{{item.id}}</div></td>
                              <td><div class="td-wrapper">{{$parent.formatDate(item.created_at)}}</div></td>
                              <td>
                                <div class="td-wrapper">
                                  <div class="item">
                                    <div class="img-container">
                                      <img v-if="item.info.doc_url != null"  class="img" :src="$parent.imgDomain + item.info.doc_url" />
                                      <div v-else class="doc-img">
                                        <img src='./../assets/doc.svg' class="img"/>
                                      </div>
                                    </div>
                                    <div class="text">{{item.info.title}}</div>
                                  </div>
                                </div>
                              </td>
                              <td><div class="td-wrapper price">{{item.sum}} {{item.currencyCode}}</div></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="content" v-if="activeProfilePage === 'transaction-history'">
                    <div class="content-wrapper">
                      <div class="table-container table-container-depositHistory">
                        <table>
                          <thead>
                            <tr v-if="$parent.depositHistory && transactionHistory.length">
                              <td>
                                <div class="td-wrapper">#</div>
                              </td>
                              <td>
                                <div class="td-wrapper">CREATED</div>
                              </td>
                              <td>
                                <div class="td-wrapper">Status</div>
                              </td>
                              <td>
                                <div class="td-wrapper">Total</div>
                              </td>
                              <td>
                                <div class="td-wrapper">Action</div>
                              </td>
                            </tr>
                            <tr v-else>
                              <td></td>
                            </tr>
                          </thead>
                          <tbody v-if="$parent.depositHistory && !transactionHistory.length">
                            <tr>
                              <td class="bold">You have no transactions</td>
                            </tr>
                          </tbody>
                          <tbody v-if="$parent.depositHistory && transactionHistory.length">
                            <tr v-for="(item,i) in transactionHistory" :key="i">
                              <td><div class="td-wrapper">{{item.id}}</div></td>
                              <td><div class="td-wrapper">{{$parent.formatDate(item.created_at)}}</div></td>
                              <td><div class="td-wrapper">
                                <span class="green" v-if="item.status === 'Success'">Success</span>
                                <span class="red" v-else-if="item.status === 'Error'">Error</span>
                                <span v-else>{{item.status}}</span>  
                              </div></td>
                              <td><div class="td-wrapper">{{item.sum}} {{item.currencyCode}}</div></td>
                              <td>
                                  <button v-if="item.status === 'Success'" @click="downloadInvoice(item.id)" class="button border">Download</button></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="content" v-if="activeProfilePage === 'purchased-products'">
                    <div class="content-wrapper">
                      <div class="table-above">
                        <div class="title big">My purchases</div>
                        <router-link to="/product-list"  class="button dark">Marketplace</router-link>
                      </div>
                      <div class="table-container table-container-orderHistory">
                        <table>
                          <thead>
                            <tr v-if="orderHistory && orderHistory.length">
                              <td>
                                <div class="td-wrapper">#</div>
                              </td>
                              <td>
                                <div class="td-wrapper">PREVIEW/NAME</div>
                              </td>
                              <td>
                                <div class="td-wrapper">Price</div>
                              </td>
                              <td>
                                <div class="td-wrapper">Action</div>
                              </td>
                            </tr>
                            <tr v-else>
                              <td></td>
                            </tr>
                          </thead>
                          <tbody v-if="orderHistory && !orderHistory.length">
                            <tr>
                              <td class="bold">You have no purchased items</td>
                            </tr>
                          </tbody>
                          <tbody v-if="orderHistory && orderHistory.length">
                            <tr v-for="(item,i) in orderHistory" :key="i">
                              <td>
                                <div class="td-wrapper">{{item.id}}</div>
                              </td>
                              <td>
                                <div class="td-wrapper">
                                  <div class="item" v-if="item">
                                    <div class="img-container">
                                      <img v-if="item.items.length && item.items[0].info.doc_url != null" class="img" :src="$parent.imgDomain + (item.items[0]).info.doc_url" />
                                      <div v-else class="doc-img">
                                        <img src='./../assets/doc.svg' class="img"/>
                                      </div>
                                    </div>
                                    <div v-if="item.items.length" class="text">{{ item.items[0].info.title}}</div>
                                  </div>
                                </div>
                                <div :class="['item-list', {'empty': !item.items.length || item.items.length < 2}]">
                                  <template v-for="(itemPart, i) in item.items">
                                    <div v-if="i !== 0" class="item" :key="i">
                                      <div class="img-container">
                                        <img v-if="itemPart.info.doc_url != null" class="img" :src="$parent.imgDomain + itemPart.info.doc_url" />
                                        <div v-else class="doc-img">
                                          <img src='./../assets/doc.svg' class="img"/>
                                        </div>
                                      </div>
                                      <div class="text">{{itemPart.info.title}}</div>
                                      <div class="price">{{itemPart.info.price}} {{item.currencyCode}}</div>
                                    </div>
                                  </template>
                                </div>
                              </td>
                              <td>
                                <div class="td-wrapper price">
                                  <span v-if="item.items.length">{{item.items[0].price}} {{item.currencyCode}}</span>
                                </div>
                              </td>
                              <td v-if="item.items && item.items.length">
                                <button @click="downloadProduct(item.items[0].info)" class="button border">Download</button>
                                <template v-for="(itemPart, i) in item.items">
                                  <button v-if="i !== 0"  :key="i" @click="downloadProduct(itemPart.info)" class="button border">Download</button>
                                </template>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="content" v-if="activeProfilePage === 'main'">
                    <div class="content-wrapper withdraw-content-wrapper">
                      <div class="user-balance-block">
                        <div class="funds item">
                          <div class="title big">My balance:</div>
                          <div class="title big">{{$parent.userBalance}} <span>{{$parent.currency}}</span></div>
                          <div class="buttons">
                            <button class="button dark" @click="$parent.openTopUpModal('deposit')">Top Up</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="content" v-if="activeProfilePage === 'profile-edit'">
                    <div class="content-wrapper">
                      <div class="personal-info">
                        <div class="table-container-wrapper">
                          <div class="title big">Personal information</div>
                          <div class="fields">
                            <div class="input-container">
                              <div class="desc"><span class="red">*</span> Электронная почта:</div>
                              <input type="email" placeholder="E-mail" v-model="email"/>
                            </div>
                            <div class="input-container">
                              <div class="desc"><span class="red">*</span> Никнейм:</div>
                              <input type="text" placeholder="Nickname" v-model="nickname"/>
                            </div>
                            <div class="input-container">
                              <div class="desc"><span class="red">*</span> Имя:</div>
                              <input type="text" placeholder="Name" v-model="name"/>
                            </div>
                            <div class="input-container">
                              <div class="desc"><span class="red">*</span> Фамилия:</div>
                              <input type="text" placeholder="Surname" v-model="surname"/>
                            </div>
                          </div>
                          <div class="button-container">
                            <button class="button border" @click="updateProfileData">
                              <span>Save changes</span>
                            </button>
                          </div>
                          <transition name="fade">
                            <div class="desc red" v-if="profileUpdateErrorMessaage">{{profileUpdateErrorMessaage}}</div>
                          </transition>
                          <transition name="fade">
                            <div class="desc green" v-if="profileUpdateSuccessMessaage">{{profileUpdateSuccessMessaage}}</div>
                          </transition>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="content" v-if="activeProfilePage === 'change-pass'">
                    <div class="content-wrapper">
                      <div class="password">
                        <div class="table-container-wrapper">
                          <div class="title big">Change password</div>
                          <div class="fields">
                            <div class="input-container">
                              <div class="desc"><span class="red">*</span> Old Password:</div>
                              <input type="text" placeholder="Enter old password" v-model="curPass"/>
                            </div>
                            <div class="input-container">
                              <div class="desc"><span class="red">*</span> New Password:</div>
                              <input type="text" placeholder="Enter a new password" v-model="newPass"/>
                            </div>
                            <div class="input-container">
                              <div class="desc"><span class="red">*</span> Repeat new password:</div>
                              <input type="text" placeholder="Repeat new password" v-model="repeatPass"/>
                            </div>
                            <div class="bottom">
                              <button class="button border" @click="updatePassword">
                                <span>Update</span>
                              </button>
                              <transition name="fade">
                                <div class="desc red" v-if="passUpdateErrorMessaage">{{passUpdateErrorMessaage}}</div>
                              </transition>
                              <transition name="fade">
                                <div class="desc green" v-if="passUpdateSuccessMessaage">{{passUpdateSuccessMessaage}}</div>
                              </transition>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>

export default {
  name: 'Profile',
  props: ['orderHistory'],
  components: {
  },
  data: function() {
    return {
      email: '',
      name: '',
      surname: '',
      phone: '',
      nickname: '',
      website: '',
      fb_url: '',
      ig_url: '',
      tt_url: '',
      joined: '',
      balance: '',
      avatar_path: '',
      profileUpdateSuccessMessaage: '',
      profileUpdateErrorMessaage: '',
      curPass: '',
      newPass: '',
      repeatPass: '',
      passUpdateSuccessMessaage: '',
      passUpdateErrorMessaage: '',
      activeProfilePage: '',
      withdrawName: '',
      withdrawEmail: '',
      profileNav: [
        {
          page: 'main',
          title: 'Balance'
        },
        {
          page: 'profile-edit',
          title: 'Account details'
        },
        {
          page: 'transaction-history',
          title: 'Transactions'
        },
        /*{
          page: 'sale-history',
          title: 'Sales'
        },
        {
          page: 'my-products',
          title: 'Created'
        },*/
        {
          page: 'purchased-products',
          title: 'Purchases'
        },
        {
          page: 'change-pass',
          title: 'Passwords'
        }
      ],
      categoryOptions: []
    }
  },
  computed: {
    sellHistory() {
      return this.$parent.depositHistory.filter(item => item.type === 'prompt');
    },
    transactionHistory() {
      return this.$parent.depositHistory;
    },
  },
  mounted() {
    this.activeProfilePage = this.$route.params.page;
    this.getProfileData();
    this.getCategories();
  },
  methods: {
    deleteProduct(id) {
      this.$http.post(process.env.VUE_APP_API + 'user/media/' + id + '/delete')
      .then((res) => {
        if (res.data.status == "OK") {
          this.$parent.getCreatedHistory();
        } else {
          this.$emit('setNewError', res.data.message)
        }
      })
      .catch((res) => {
        this.$emit('setNewError', res.response.data.message)
      })
    },
    downloadInvoice(id) {
      this.$http.get(process.env.VUE_APP_API + 'user/invoice/download?id=' + id)
      .then((res) => {
        window.location = res.config.url;
      })
      .catch((res) => {
        this.$emit('setNewError', res.response.data.message)
      })
    },
    downloadProduct(product) {
      this.$http.get(process.env.VUE_APP_API + 'user/media/download?id=' + product.id)
      .then((res) => {
        window.location = res.config.url;
      })
      .catch((res) => {
        this.$emit('setNewError', res.response.data.message)
      })
    },
    getCategories() {
      this.$http.get(process.env.VUE_APP_API + 'categories')
      .then((res) => {
        this.categoryOptions = res.data.payload;
      })
      .catch(() => {
         
      })
    },
    updateProfileData() {
      let profileData = {
        "email": this.email,
        "name": this.name,
        "phone": this.phone,
        "surname": this.surname,
        "nickname": this.nickname,
        "web_url": this.website,
        "fb_url": this.fb_url,
        "ig_url": this.ig_url,
        "tt_url": this.tt_url
      }
      this.$http.put(process.env.VUE_APP_API + 'user/profile', profileData)
      .then((res) => {
        if (res.data.status == "OK") {
          this.profileUpdateSuccessMessaage = 'Success';
          setTimeout(() => {
            this.profileUpdateSuccessMessaage = '';
          }, 3000);
        }
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignInModal();
        }
        if (res.response.data.errors.email) {
          this.profileUpdateErrorMessaage = res.response.data.errors.email[0];
        } else if (res.response.data.message) {
          this.profileUpdateErrorMessaage = res.response.data.message;
        }
        let self = this;
        setTimeout(() => {
          self.profileUpdateErrorMessaage = '';
        }, 3000);
      })
    },
    updatePassword() {
      let passwordList = {
        "cur_password": this.curPass,
        "new_password": this.newPass,
        "repeat_password": this.repeatPass
      }
      this.$http.post(process.env.VUE_APP_API + 'user/change-password', passwordList)
      .then((res) => {
        if (res.data.status == "OK") {
          this.passUpdateSuccessMessaage = 'Success';
          this.curPass = '',
          this.newPass = '',
          this.repeatPass = '';
          setTimeout(() => {
            this.passUpdateSuccessMessaage = '';
          }, 3000);
        }
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignInModal();
        }
        this.passUpdateErrorMessaage = res.response.data.message;
        setTimeout(() => {
          this.passUpdateErrorMessaage = '';
        }, 3000);
      })
    },
    goToProfilePage(page) {
      this.$router.push({path: '/profile/' + page});
      this.activeProfilePage = page;
    },
    getProfileData() {
      this.$http.get(process.env.VUE_APP_API + 'user/profile')
      .then((res) => {
          this.email = res.data.email;
          this.name = res.data.name;
          this.surname = res.data.surname;
          this.phone = res.data.phone;
          this.joined = res.data.created_at;
          this.balance = res.data.balance;
          this.nickname = res.data.nickname;
          this.website = res.data.web_url;
          this.fb_url = res.data.fb_url;
          this.ig_url = res.data.ig_url;
          this.tt_url = res.data.tt_url;
          this.avatar_path = res.data.avatar_path;
          
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignInModal();
        }
      })
    },
    editPrompt(item) {
      this.$emit('editPrompt', item);
    }
  }
}
</script>